import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Section from '../../../src/components/Section'
import { graphql } from 'gatsby'

export default ({ location }) => (
    <Layout location={location} noPad={true}>
        <Helmet>
            <title>JuriGo &mdash; POLITIQUE DE CONFIDENTIALITÉ</title>
        </Helmet>
        <Section>
            <h1>POLITIQUE DE CONFIDENTIALITÉ DE JURIGO.CA</h1>
            <p align="center">Date de la dernière modification : 2020-01-01</p>
            <p>
                La confidentialité de vos renseignements personnels est de la
                plus grande importance pour JuriGo inc. (« JuriGo.ca » ou « nous
                »). Cette politique de confidentialité concerne les informations
                que nous recueillons sur vous ou que vous nous communiquez par
                l’entremise de notre site Web localisé au www.JuriGo.ca (le «
                Site ») ou autrement. Elle explique également comment nous
                utilisons les témoins (cookies) et d’autres technologies
                similaires. En nous communiquant vos informations personnelles
                ou en visitant notre Site, vous acceptez d’être lié par les
                termes, conditions et modalités de cette politique de
                confidentialité.
            </p>
            <h2>1. COLLECTE DES RENSEIGNEMENTS PERSONNELS</h2>
            <p>
                Nous recueillons vos informations personnelles lorsque vous
                créez un compte sur notre Site. À cette occasion, vous devez
                fournir vos nom et prénom et votre adresse courriel. Vous pouvez
                également, à votre discrétion, nous fournir d’autres
                renseignements. Ces informations ne servent qu’à gérer votre
                compte et à assurer le fonctionnement de notre Site et sont
                détruites lorsque votre compte est fermé.
            </p>
            <p>
                Nous pouvons recueillir votre nom, vos coordonnés, votre adresse
                courriel et d’autres types de renseignements semblables lorsque
                vous interagissez avec nous ou faites affaire avec nous, par
                exemple lorsque vous nous demandez des renseignements ou lorsque
                vous communiquez avec nous ou par l’intermédiaire de notre Site.
                Nous pouvons également recueillir toute information que vous
                nous transmettez en publiant sur notre Site. Ces renseignements
                sont utilisés et stockés aux fins de répondre à vos besoins,
                questions et demandes, d’établir des statistiques de nature
                générale de gérer notre relation avec vous ou pour gérer le
                contenu que vous avez publié sur notre Site.
            </p>
            <p>
                Nous pouvons également recueillir vos nom, coordonnées, adresse
                courriel et toute autre information que vous nous transmettez
                lorsque vous recevez une demande de rendez-vous via notre Site.
                Nous recueillons également des données pour les paiements. Par
                exemple, nous recueillons votre numéro de carte de crédit. Nous
                conservons les informations sur les demandes et paiements qui
                sont passés sur notre Site.
            </p>
            <h2>2. FICHIER TÉMOIN (COOKIE)</h2>
            <p>
                Comme beaucoup d’autres sites Web, notre Site utilise des
                témoins (cookies). Un témoin est un très petit fichier texte qui
                est automatiquement déposé dans le disque dur de l’ordinateur du
                visiteur. Ces fichiers nous aident à assurer le bon
                fonctionnellement de notre Site, à personnaliser, à faciliter et
                à améliorer votre visite sur notre Site. Nous les utilisons pour
                géo-localiser les utilisateurs et mesurer la durée de leur
                visite.
            </p>
            <p>
                Nous ne recoupons pas les renseignements recueillis
                automatiquement par les témoins avec d’autres types de
                renseignements personnels.
            </p>
            <p>
                Si vous préférez ne pas accepter les témoins, vous pouvez
                configurer votre navigateur Internet pour qu’il vous avertisse
                lorsque votre ordinateur reçoit un témoin ou pour les refuser
                automatiquement. L’utilisation du témoin n’étant pas nécessaire,
                l’utilisateur qui refuse les témoins peut naviguer librement à
                travers toutes les pages, mais perd ainsi certains avantages du
                Site.
            </p>
            <h2>3. UTILISATION DE GOOGLE ANALYTICS</h2>
            <p>
                Nous utilisons sur notre Site Google Analytics, un outil
                permettant de générer des rapports sur les interactions des
                utilisateurs avec les sites Web.
            </p>
            <p>
                Google Analytics collecte, principalement à l’aide de témoins
                (cookies), des renseignements concernant votre navigation sur
                notre Site qui sont ensuite transmis à Google et conservés sur
                des serveurs situés notamment aux États-Unis. Google ne peut
                communiquer ces renseignements à des tiers sauf dans certaines
                circonstances limitées, telles qu’en cas d’obligation légale ou
                lorsque des tiers traitent les données pour son compte.
            </p>
            <p>
                Les renseignements collectés par Google Analytics sont, par
                exemple, les pages que vous consultez, vos interactions avec les
                contenus, votre type de navigateur et système d’exploitation,
                votre adresse IP ainsi que la date, l’heure, la durée et la
                fréquence de vos visites.
            </p>
            <p>
                Google n’utilise les renseignements collectés que pour produire
                des statistiques et des rapports sur la navigation sur notre
                Site, ce qui nous permet d’améliorer nos services.
            </p>
            <p>
                Pour obtenir de plus amples renseignements, nous vous invitons à
                consulter le{' '}
                <a href="https://www.google.com/intl/fr/analytics/#?modal_active=none">
                    site Web
                </a>{' '}
                de Google Analytics et sa{' '}
                <a href="https://policies.google.com/?hl=fr-CA">
                    politique de confidentialité
                </a>
                .
            </p>
            <p>
                Si vous ne souhaitez pas transmettre d'informations à Google
                Analytics, vous pouvez téléchargez et installez{' '}
                <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">
                    ce module
                </a>{' '}
                dans votre navigateur Web
            </p>
            <h2>4. NON-DIVULGATION DES RENSEIGNEMENTS PERSONNELS</h2>
            <p>
                Nous ne transmettons aucun renseignement personnel recueilli par
                l’intermédiaire de notre Site à quiconque sans votre
                consentement. Cependant, nous nous réservons le droit de
                divulguer de tels renseignements personnels si la loi l’exige ou
                si nous croyons de bonne foi qu’une telle mesure est nécessaire
                pour se conformer à la loi ou à une procédure judiciaire, pour
                nous protéger, défendre nos droits ou nos biens ou pour
                collaborer à la détection ou à la prévention d’activités
                illégales.
            </p>
            <h2>5. HYPERLIENS</h2>
            <p>
                Cette politique de confidentialité s’applique uniquement à notre
                Site. Or, notre Site peut contenir des hyperliens permettant à
                ses visiteurs de visiter d’autres sites Web qui ne sont pas
                exploités conformément à cette politique de confidentialité.
                Nous vous recommandons de consulter les politiques des
                exploitants de ces sites externes pour connaître la façon dont
                vos renseignements personnels seront recueillis, utilisés et
                divulgués.
            </p>
            <h2>6. DURÉE DE CONSERVATION DE L’INFORMATION</h2>
            <p>
                Les renseignements personnels sur les utilisateurs qui sont
                recueillies par l’entremise de notre Site sont détruits ou
                effacés dès que nous n’en avons plus besoin pour les fins
                auxquelles ils ont été fournis.
            </p>
            <h2>7. VENTE D’ENTREPRISE</h2>
            <p>
                Au cas où nous aurions l’intention de vendre ou de transférer la
                propriété ou le contrôle de toute ou partie de notre entreprise,
                de nos opérations ou de nos services à un tiers, nous nous
                réservons le droit de divulguer vos informations personnelles à
                un acheteur potentiel avant et après la vente. Toutefois, si la
                vente a lieu, nous exigerons que l’acheteur soit lié par les
                dispositions de cette politique de confidentialité. Si la vente
                n’a pas lieu, nous exigerons que l’acheteur potentiel n’utilise
                ni ne divulgue vos informations personnelles d’aucune façon et
                procède à leur destruction.
            </p>
            <h2>8. SÉCURITÉ</h2>
            <p>
                Nous utilisons des mesures de sécurité raisonnables pour
                protéger vos informations personnelles contre les accès non
                autorisés. Notre Site bénéficie d’un certificat SSL pour
                sécuriser les données et les transactions. Seuls les membres
                autorisés de notre personnel ont accès à ces informations.
            </p>
            <p>
                Nous vous prions d’être prudents si vous nous transmettez des
                informations personnelles par courriel. Le courriel n’étant pas
                toujours un moyen sécuritaire pour transmettre de l’information,
                nous ne pouvons garantir que ces informations ne seront pas
                perdues, modifiées ou utilisées de manière frauduleuse. Nous
                vous encourageons à utiliser le cryptage de courriels pour
                communiquer avec nous.
            </p>
            <h2>9. ACCÈS</h2>
            <p>
                Les renseignements personnels des utilisateurs sont conservés
                par nous et peuvent être consultés en transmettant une{' '}
                <u>demande écrite en ce sens</u>. Vous pouvez également demander
                la rectification de l’un de vos renseignements personnels.
                Cependant, votre droit d’accéder ou d’apporter des corrections à
                vos renseignements personnels est assujetti aux restrictions
                applicables prévues par la loi.
            </p>
            <p>
                Toute question, commentaire ou demande relative à cette
                politique de confidentialité doit être adressée par courriel au{' '}
                <a href="mailto:info@jurigo.ca">info@jurigo.ca</a>.
            </p>
            <h2>10. MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
            <p>
                La présente politique de confidentialité peut être modifiée à
                l’occasion. Nous vous aviserons de toute modification par
                courriel et par une publication affichée sur la page d’accueil
                de notre Site. Les modifications entreront en vigueur
                automatiquement 60 jours après leur publication. Votre
                utilisation de notre Site ou le fait de continuer à nous fournir
                vos renseignements personnels après ce délai de 60 jours
                équivaudra à une acceptation de ces modifications.
            </p>
            <h2>11. TRADUCTION</h2>
            <p>
                En cas de divergences de signification ou d’interprétation entre
                la version originale française de la présente politique de
                confidentialité et une traduction, la version originale
                française prévaut.
            </p>
        </Section>
    </Layout>
)

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
